<template>
    <MainLayout title="Login">
        <Head title="Login" />
        <!-- Image Background with better mobile handling -->
        <div class="fixed inset-0 z-0">
            <img
                :src="loginBg"
                alt="Background"
                class="w-full h-full object-cover"
                loading="eager"
            >
            <div class="absolute inset-0 bg-black/50"></div>
        </div>

        <!-- Main container with improved mobile spacing -->
        <div class="min-h-screen flex flex-col justify-center items-center px-4 py-8 sm:py-0 relative z-10">
            <div class="w-full max-w-[95%] sm:max-w-md mt-6 bg-white shadow-lg rounded-2xl">
                <!-- Tabs with better touch targets -->
                <div class="flex border-b">
                    <button
                        @click="switchType('email')"
                        :class="[
                            'w-1/2 text-center py-4 text-sm sm:text-base font-medium focus:outline-none transition-colors',
                            type === 'email'
                                ? 'border-b-2 border-[#2B8B6F] text-[#2B8B6F]'
                                : 'text-gray-500 hover:text-gray-700'
                        ]"
                    >
                        Email
                    </button>
                    <button
                        @click="switchType('phone')"
                        :class="[
                            'w-1/2 text-center py-4 text-sm sm:text-base font-medium focus:outline-none transition-colors',
                            type === 'phone'
                                ? 'border-b-2 border-[#2B8B6F] text-[#2B8B6F]'
                                : 'text-gray-500 hover:text-gray-700'
                        ]"
                    >
                        Phone
                    </button>
                </div>

                <div class="px-4 sm:px-8 py-6">
                    <div v-if="!otpSent">
                        <form @submit.prevent="sendOTP" class="space-y-4">
                            <!-- Email Login with improved mobile input -->
                            <div v-if="type === 'email'">
                                <label class="block text-gray-600 text-sm font-bold mb-2">
                                    Email Address
                                </label>
                                <div class="relative">
                                    <i class="fas fa-envelope absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"></i>
                                    <input
                                        type="email"
                                        v-model="email"
                                        placeholder="Enter your email"
                                        class="w-full pl-10 px-4 py-3 text-sm sm:text-base border rounded-xl focus:outline-none focus:ring-2 focus:ring-[#2B8B6F] focus:border-transparent"
                                        required
                                    />
                                    <div v-if="errors.email" class="text-red-500 text-xs sm:text-sm mt-1">
                                        {{ errors.email }}
                                    </div>
                                </div>
                            </div>

                            <!-- Phone Login with improved mobile input -->
                            <div v-else class="space-y-2">
                                <label class="block text-gray-600 text-sm font-bold">
                                    Phone Number
                                </label>
                                <PhoneInput
                                    v-model="phone"
                                    :error="errors.phone"
                                    class="relative z-50"
                                />
                            </div>

                            <!-- Responsive buttons and links -->
                            <div class="flex flex-col sm:flex-row justify-between items-center gap-4 mt-6">
                                <button
                                    type="submit"
                                    :disabled="processing"
                                    class="w-full sm:w-auto px-6 py-3 bg-[#2B8B6F] text-white rounded-xl hover:bg-[#24745C] flex items-center justify-center gap-2 transition duration-200 order-1 disabled:opacity-60 disabled:cursor-not-allowed"
                                >
                                    <span v-if="processing">Processing...</span>
                                    <span v-else>Send OTP</span>
                                </button>
                                
                                <p class="text-gray-600 text-sm order-2">
                                    Don't have an account?
                                    <Link
                                        :href="route('register')"
                                        class="text-[#2B8B6F] font-medium hover:text-[#24745C]"
                                    >
                                        Register Now
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </div>

                    <!-- OTP Verification with improved mobile layout -->
                    <div v-else class="space-y-4">
                        <h2 class="text-xl sm:text-2xl font-bold text-center text-gray-700">
                            Enter OTP
                        </h2>
                        <form @submit.prevent="verifyOTP" class="space-y-4">
                            <div>
                                <label class="block text-gray-600 text-sm font-bold mb-2">
                                    Enter OTP sent to {{ type === 'email' ? email : phone }}
                                </label>
                                <div class="flex justify-center sm:justify-between gap-1.5 sm:gap-2">
                                    <input
                                        v-for="(digit, index) in 6"
                                        :key="index"
                                        :ref="el => otpInputs[index] = el"
                                        type="text"
                                        inputmode="numeric"
                                        pattern="\d*"
                                        maxlength="1"
                                        v-model="otpDigits[index]"
                                        @input="handleOtpInput($event, index)"
                                        @keydown="handleOtpInput($event, index)"
                                        @paste="handlePaste"
                                        @focus="$event.target.select()"
                                        class="w-10 h-10 sm:w-12 sm:h-12 text-center text-lg sm:text-xl border rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#2B8B6F] focus:border-transparent transition-all duration-200"
                                        required
                                    />
                                </div>
                                <div v-if="errors.otp" class="text-red-500 text-xs sm:text-sm mt-1 text-center sm:text-left">
                                    {{ errors.otp }}
                                </div>
                            </div>

                            <div class="flex flex-col sm:flex-row items-center justify-between gap-4">
                                <button
                                    type="button"
                                    @click="resendOTP"
                                    :disabled="resendTimer > 0 || processing"
                                    class="text-sm text-gray-600 hover:text-[#2B8B6F] focus:outline-none disabled:opacity-60 disabled:cursor-not-allowed order-2 sm:order-1"
                                >
                                    {{ resendTimer > 0 ? `Resend in ${resendTimer}s` : 'Resend OTP' }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="processing"
                                    class="w-full sm:w-auto px-6 py-3 bg-[#2B8B6F] text-white rounded-xl hover:bg-[#24745C] flex items-center justify-center gap-2 transition duration-200 order-1 sm:order-2 disabled:opacity-60 disabled:cursor-not-allowed"
                                >
                                    <span v-if="processing">Processing...</span>
                                    <span v-else>Verify OTP</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import { defineComponent } from 'vue'
import { Head, Link } from '@inertiajs/vue3'
import axios from 'axios'
import MainLayout from "@/Layouts/MainLayout.vue"
import PhoneInput from "@/Components/PhoneInput.vue"
import loginBg from '~/images/login.jpg'

export default defineComponent({
    components: {
        MainLayout,
        Head,
        Link,
        PhoneInput
    },

    data() {
        return {
            loginBg,
            otpSent: false,
            processing: false,
            resendTimer: 0,
            timerInterval: null,
            type: 'email',
            email: '',
            phone: '',
            otpDigits: Array(6).fill(''),
            otpInputs: Array(6).fill(null),
            errors: {}
        }
    },


    methods: {
        switchType(newType) {
            this.type = newType;
            this.otpSent = false;
            this.errors = {};
            this.otpDigits = Array(6).fill('');
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
                this.resendTimer = 0;
            }
        },

        async sendOTP() {
            if (this.processing) return;

            this.processing = true;
            this.errors = {};

            try {
                await axios.post('/api/auth/send-otp', {
                    type: this.type,
                    email: this.type === 'email' ? this.email : undefined,
                    phone: this.type === 'phone' ? this.phone : undefined,
                    context: 'login'
                });

                this.otpSent = true;
                this.startResendTimer();
            } catch (error) {
                if (error.response?.data?.message) {
                    this.errors[this.type] = error.response.data.message;
                } else if (error.response?.data?.errors) {
                    this.errors = error.response.data.errors;
                } else {
                    this.errors[this.type] = 'Failed to send OTP. Please try again.';
                }
                console.error('Send OTP Error:', error.response?.data);
            } finally {
                this.processing = false;
            }
        },

        async verifyOTP() {
            if (this.processing) return;

            this.processing = true;
            this.errors = {};

            try {
                const response = await axios.post('/api/auth/verify-otp', {
                    type: this.type,
                    // identifier: this.type === 'email' ? this.email : this.phone,
                    phone: this.phone,
                    email: this.email,
                    otp: this.otpDigits.join('')
                });

                if (response.data.user) {
                    window.location.href = route('dashboard');
                }
            } catch (error) {
                if (error.response?.data?.message) {
                    this.errors.otp = error.response.data.message;
                } else if (error.response?.data?.errors) {
                    this.errors = error.response.data.errors;
                } else {
                    this.errors.otp = 'Invalid OTP';
                }
                console.error('Verify OTP Error:', error.response?.data);
            } finally {
                this.processing = false;
            }
        },

        handleOtpInput(event, index) {
            // Get the input value and key pressed
            const value = event.target.value;

            // If a number was entered
            if (/^\d$/.test(value)) {
                // Update the current digit
                this.otpDigits[index] = value;

                // Move to next input if available
                if (index < 5 && this.otpInputs[index + 1]) {
                    this.otpInputs[index + 1].focus();
                }
            }
            // Handle backspace
            else if (event.key === 'Backspace') {
                // Clear current digit
                this.otpDigits[index] = '';

                // Move to previous input if available
                if (index > 0 && this.otpInputs[index - 1]) {
                    this.otpInputs[index - 1].focus();
                }
            }

            // Prevent non-numeric input
            if (!/^\d$/.test(value)) {
                this.otpDigits[index] = '';
            }
        },

        handlePaste(event) {
            event.preventDefault();
            const pastedData = event.clipboardData.getData('text').trim();

            // Only process if the pasted data contains numbers
            if (/^\d+$/.test(pastedData)) {
                const digits = pastedData.slice(0, 6).split('');
                this.otpDigits = [...digits, ...Array(6 - digits.length).fill('')];

                // Focus the next empty input or the last input if all are filled
                const nextEmptyIndex = this.otpDigits.findIndex(digit => digit === '');
                const focusIndex = nextEmptyIndex === -1 ? 5 : nextEmptyIndex;

                if (this.otpInputs[focusIndex]) {
                    this.otpInputs[focusIndex].focus();
                }
            }
        },

        async resendOTP() {
            if (this.resendTimer === 0 && !this.processing) {
                await this.sendOTP();
            }
        },

        startResendTimer() {
            this.resendTimer = 30;
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
            this.timerInterval = setInterval(() => {
                this.resendTimer--;
                if (this.resendTimer === 0) {
                    clearInterval(this.timerInterval);
                }
            }, 1000);
        }
    },

    beforeUnmount() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }
});
</script>

<style scoped>
/* Ensure buttons have good touch targets on mobile */
button {
    touch-action: manipulation;
}

/* Improve focus styles for better accessibility */
:focus-visible {
    outline: 2px solid #2B8B6F;
    outline-offset: 2px;
}

/* Ensure proper stacking context */
.relative.z-50 {
    isolation: isolate;
}

/* Optimize background image container */
.fixed.inset-0 {
    will-change: transform;
}

/* Input placeholder styling */
input::placeholder {
    color: #9CA3AF;
}

/* Improve form field readability */
input[type="text"],
input[type="email"] {
    font-size: 16px; /* Prevents iOS zoom on focus */
}

/* OTP input specific styles */
input[type="text"][maxlength="1"] {
    -moz-appearance: textfield;
}

input[type="text"][maxlength="1"]::-webkit-outer-spin-button,
input[type="text"][maxlength="1"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Improve mobile responsiveness */
@media (max-width: 640px) {
    input[type="text"][maxlength="1"] {
        width: 2.75rem;
        height: 2.75rem;
        font-size: 1.125rem;
    }
}

@media (max-width: 380px) {
    input[type="text"][maxlength="1"] {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
    }
}

/* Disable autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* Smooth transitions */
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

/* Improve button press state */
button:active:not(:disabled) {
    transform: scale(0.98);
}

/* Better tap highlight color for mobile */
* {
    -webkit-tap-highlight-color: transparent;
}

/* Prevent text selection on interactive elements */
button, input[type="text"][maxlength="1"] {
    user-select: none;
}

/* Improve input field hover states */
input:hover:not(:disabled):not(:focus) {
    border-color: #D1D5DB;
}

/* Better disabled state styling */
button:disabled, input:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Improve OTP input touch targets */
input[type="text"][maxlength="1"] {
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}
</style>